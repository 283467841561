
require('./zdravology');

import Vue from 'vue';
import store from './store';
import { notify, trans, chunk } from './functions';
import VueToast from 'vue-toast-notification';
import vClickOutside from 'v-click-outside';
import VPagination from './components/VPagination.vue';
import HeaderSearch from './components/layout/HeaderSearch.vue';
import FlexiTab from './components/home/FlexiTab';
import ProductIndex from './components/products/Index';
import ProductCardGridView from './components/products/index/ProductCardGridView.vue';
import ProductShow from './components/products/Show';
import CartIndex from './components/cart/Index';
import CheckoutCreate from './components/checkout/Create';
import MyWishlist from './components/account/wishlist/Index';
import ProductCard from "./components/ProductCard";
import ProductQuickView from "./components/ProductQuickView";
import ProductSwitcher from "./components/home/ProductSwitcher";
import MyAddresses from './components/account/addresses/Index';
import HomeFeatures from "./components/home/HomeFeatures";
import ProductGrid from "./components/home/ProductGrid";

Vue.prototype.route = route;
Vue.prototype.$notify = notify;
Vue.prototype.$trans = trans;
Vue.prototype.$chunk = chunk;

Vue.use(VueToast);
Vue.use(vClickOutside);

Vue.component('v-pagination', VPagination);
Vue.component('header-search', HeaderSearch);
Vue.component('product-show', ProductShow);
Vue.component('product-card', ProductCard);
Vue.component('cart-index', CartIndex);
Vue.component('checkout-create', CheckoutCreate);
Vue.component('flexi-tab', FlexiTab);
Vue.component('product-index', ProductIndex);
Vue.component('product-card-grid-view', ProductCardGridView);
Vue.component('product-quick-view', ProductQuickView);
Vue.component('my-wishlist', MyWishlist);
Vue.component('product-switcher', ProductSwitcher);
Vue.component('my-addresses', MyAddresses);
Vue.component('home-features', HomeFeatures); // todo prepisat


// Vue.component('sidebar-cart', SidebarCart);
// Vue.component('newsletter-popup', NewsletterPopup);
// Vue.component('newsletter-subscription', NewsletterSubscription);
// Vue.component('cookie-bar', CookieBar);
// Vue.component('landscape-products', LandscapeProducts);
// Vue.component('featured-categories', FeaturedCategories);
// Vue.component('banner-three-column-full-width', BannerThreeColumnFullWidth);
// Vue.component('top-brands', TopBrands);
// Vue.component('flash-sale-and-vertical-products', FlashSaleAndVerticalProducts);
// Vue.component('flash-sale', FlashSale);
// Vue.component('banner-two-column', BannerTwoColumn);
// Vue.component('vertical-products', VerticalProducts);
Vue.component('product-grid', ProductGrid);
// Vue.component('banner-three-column', BannerThreeColumn);
// Vue.component('product-tabs-two', ProductTabsTwo);
// Vue.component('banner-one-column', BannerOneColumn);
// Vue.component('product-card-list-view', ProductCardListView);
// Vue.component('product-card-vertical', ProductCardVertical);
// Vue.component('compare-index', CompareIndex);
// Vue.component('dynamic-dropdown', DynamicDropdown);
// Vue.component('my-addresses', MyAddresses);



new Vue({
    el: '#app',

    computed: {
        basket() {
            return store.state.basket;
        },

	    quickViewProduct() {
		    return store.state.quickViewProduct;
	    },

	    wishlistCount() {
            return store.wishlistCount();
        },
    },
});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': App.csrfToken,
    },
});
