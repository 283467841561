require('./vendors/vendors');

$(() => {
    let select = $('.custom-select-option');
	//todo uncomment
    // select.niceSelect();
    select.on('change', (e) => {
        e.target.dispatchEvent(new Event('nice-select-updated', { bubbles: true }));
    });


    $('.post_category ul li i').on('click', (e) => {
        $(e.currentTarget).toggleClass('icofont-simple-right icofont-simple-down').siblings('.dropdown-menu').slideToggle(300);
    });

    /*      image gallery
    /*----------------------------------------*/

    let baseImage = $('.product-picture');



});
